<template>
	<div>
		<CForm>
			<CRow>
				<CCol sm="12">
					<CCard>
						<CCardHeader>
							<strong>{{ $t('common.addAddress') }}</strong>
						</CCardHeader>
						<CCardBody>
							<CForm>
								<CInput :placeholder="$t('pages.account.fullName')" v-model="values.fullName" horizontal :isValid="!errors.fullName" :invalidFeedback="errors.fullName">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.account.fullName') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<CInput :placeholder="$t('common.enterEmail')" v-model="values.email" horizontal :isValid="!errors.email" :invalidFeedback="errors.email">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('common.email') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<CInput :placeholder="$t('common.enterPhoneNumber')" v-model="values.phoneNumber" horizontal maxlength=12 :isValid="!errors.phoneNumber" :invalidFeedback="errors.phoneNumber">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('common.phone') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>								

								<CSelect horizontal :options="provinceOptions" :value.sync="values.provinceId"
										 :isValid="!errors.provinceId" :invalidFeedback="errors.provinceId" @change="onChangeProvince">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.agent.selectProvince') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CSelect>

								<CSelect horizontal :options="districtOptions" :value.sync="values.districtId"
										 :isValid="!errors.districtId" :invalidFeedback="errors.districtId" @change="onChangeDistrict">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.agent.selectDistrict') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CSelect>

								<CSelect horizontal :options="wardOptions" :value.sync="values.wardId"
										 :isValid="!errors.wardId" :invalidFeedback="errors.wardId">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.agent.selectWard') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CSelect>

								<CInput :placeholder="$t('pages.account.enterAddress')" v-model="values.address" horizontal :isValid="!errors.address" :invalidFeedback="errors.address">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('common.address') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<div class="form-actions">
									<CButton type="button" color="primary" @click="onSaveNewAddress">{{ $t('common.save') }}</CButton>
									<CButton type="button" color="secondary" @click="onCancel">{{ $t('common.cancel') }}</CButton>
								</div>
							</CForm>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</CForm>
	</div>
</template>

<script>
	
    import i18n from '@/plugins/i18n'
	import { mapGetters, mapState, mapActions } from 'vuex'
	

	import _ from 'lodash'
	import * as yup from 'yup'
	import { rawListeners } from 'process';
	
	const userInfoSchema = yup.object().shape({		
		email: yup.string().required("Nhập email").email('Email không hợp lệ'),
		fullName: yup.string().required("Nhập họ và tên đầy đủ"),
		phoneNumber: yup.string().required("Nhập số điện thoại"),				
        provinceId: yup.string().when('isCreateAgentAutomation', {
            is: true,
            then: yup.string().required(i18n.t('pages.agent.selectProvince'))
		}),
        districtId: yup.string().when('isCreateAgentAutomation', {
            is: true,
            then: yup.string().required(i18n.t('pages.agent.selectDistrict'))
		}),
        wardId: yup.string().when('isCreateAgentAutomation', {
            is: true,
            then: yup.string().required(i18n.t('pages.agent.selectWard'))
        }),

		address: yup.string().required("Nhập địa chỉ"),
    });

	const initialErrors = {		
		email: '',
		fullName: '',
		phoneNumber: '',				
		provinceId: '',
		districtId: '',
        wardId: '',
		address: '',
	};

	export default {
		name: 'Add-Address',
		data() {
			return {
				horizontal: { label:'col-3', input:'col-9' },	
				invalidInput: false,
				errorInput: '',				
				values: {					
					email: '',
					fullName: '',
					phoneNumber: '',									
					provinceId: '',
					districtId: '',
					wardId: '',
					address: '',
				},
				errors: {
					email: '',
					fullName: '',
					phoneNumber: '',									
					provinceId: '',
					districtId: '',
					wardId: '',
					address: '',
				},											
			}
		},

        computed: {						
            ...mapGetters('location', ['listProvinces', 'listDistricts', 'listWards']),		
			provinceOptions() {
				let options = [];
				if (this.listProvinces != null && this.listProvinces.length > 0) {
                    options = this.listProvinces.map(item => {
                        return { value: item.provinceId, label: item.name };
                    });

                    options.splice(0, 0,
                        {
                            value: '',
                            label: i18n.t('pages.agent.selectProvince')
                        });
				}

                return options;
			},
            districtOptions() {
                let options = [];
                if (this.listDistricts != null && this.listDistricts.length > 0) {
                    options = this.listDistricts.map(item => {
                        return { value: item.districtId, label: item.name };
                    });                    
                }

				options.splice(0, 0,
                        {
                            value: '',
                            label: i18n.t('pages.agent.selectDistrict')
                        });

                return options;
			},
            wardOptions() {
				let options = [];
                if (this.listWards != null && this.listWards.length > 0) {
                    options = this.listWards.map(item => {
                        return { value: item.wardId, label: item.name };
                    });                    
				}

				options.splice(0, 0,
                        {
                            value: '',
                            label: i18n.t('pages.agent.selectWard')
                        });


                return options;
			},							
        },  
		methods: {			    			
			validate(field) {
                userInfoSchema.validateAt(field, this.values)
                    .then(() => this.errors[field] = '')
                    .catch((err) => {
                        this.errors[err.path] = err.message
                        console.log('validate error', err);
                    });
            },

			async validateAll() {
				console.log("CONTEXT VALIDATE ALL",this) 
				try {
					this.errors = {...initialErrors};
                    await userInfoSchema.validate(this.values, { abortEarly: false });
					return true;
                }
                catch (err) {
                    _.forEach(err.inner, error => {
                        this.errors[error.path] = error.message;
                    })
					console.log("all errors", this.errors)
                    return false;
                }
			},

			async onSaveNewAddress() {			
				var isValid = await this.validateAll();
                
				if(!isValid)
					return;               			

				var response = await this.$locationService.addNewAddress({                   
                    fullName: this.values.fullName,
                    email: this.values.email,
                    phoneNumber: this.values.phoneNumber,
                    address: this.values.address,
					provinceId: this.values.provinceId,
                    districtId: this.values.districtId,
                    wardId: this.values.wardId,
                });
               console.log("addNewAddress 1 ===", response);
				if (response != null && response.id > 0) {
					this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.address.noticeAddAddressSuccess') }, { root: true });
					this.$router.go(-1);	//this.$router.push('/users/list/');			
					return;
				}
				
				if (response != null && response.data == false && response.errorMessage != '') {							
					this.$store.dispatch('alert/error', i18n.t('pages.address.noticeAddAddressFailed'), { root: true });
				}
			},

			onCancel(){
					this.$router.go(-1);
			},	
            async onChangeProvince(event) {
                await this.$store.dispatch("location/getDistrictsByProviceId", event.target.value);
			},
            async onChangeDistrict(event) {
                await this.$store.dispatch("location/getWardsByDistrictId", event.target.value);
            }, 
		},
		
		async mounted() {					
			//this.$store.dispatch("location/initNewAddress");
			this.$store.dispatch("location/getAllProvinces");     						 
		}
	}
</script>

